import React from 'react';
import Image from 'react-bootstrap/Image';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import icon from '../images/arrow_down.png';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import '../styles/houses.scss';

const backendAddress = process.env.GATSBY_COCKPIT_URL;

class HousesMap extends React.Component {
  state = {
    height: 1513,
    width: 2160,
    hoveredHouse: '',
    showOnlyAvailable: false,
    showHouses: false,
  };

  basicHeight = 1513;
  basicWidth = 2160;

  componentDidMount() {
    document.getElementById('main-background').onload = this.firstResize;
    window.addEventListener('resize', this.onResize);
    window.addEventListener('contextmenu', e => {
      e.preventDefault();
    });
    this.firstResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  firstResize = () => {
    Array.from(document.getElementsByClassName('js-house-images')).forEach(
      img => {
        const width = (img.naturalWidth / this.basicWidth) * 100;
        const height = (img.naturalHeight / this.basicHeight) * 100;
        img.style.width = `${width}%`;
        img.style.height = `${height}%`;
      }
    );
    this.onResize();
    this.setState({
      showHouses: true,
    });
  };

  onResize = () => {
    const mainBackgroundEl = document.getElementById('main-background');
    if (mainBackgroundEl) {
      if (
        mainBackgroundEl.offsetHeight > 0 &&
        mainBackgroundEl.offsetWidth > 0
      ) {
        this.setState({
          height: mainBackgroundEl.offsetHeight,
          width: mainBackgroundEl.offsetWidth,
        });
      }
    }
    this.setState(
      {
        showHouses: false,
      },
      () => {
        this.setState({
          showHouses: true,
        });
      }
    );
  };

  checkTransparency = debounce(e => {
    const { left, top } = e.target.getBoundingClientRect();
    const x = e.pageX - left;
    const y = e.pageY - top;

    if (this.isTransparent(x, y, e.target)) {
      e.target.style.visibility = 'hidden';
      const lowerElement = document.elementFromPoint(e.clientX, e.clientY);
      e.target.style.visibility = 'visible';
      if (lowerElement.classList.contains('house')) {
        const xNew = e.pageX - lowerElement.getBoundingClientRect().left;
        const yNew = e.pageY - lowerElement.getBoundingClientRect().top;
        this.isTransparent(xNew, yNew, lowerElement);
      }
    }
  }, 200);

  isTransparent = (x, y, target) => {
    const canvas =
      document.getElementById(target.id + '-canvas') || createCanvas(target);

    if (canvas.getContext === undefined) {
      return true;
    }
    const ctx = canvas.getContext('2d');
    if (x && y) {
      if (ctx.getImageData(x, y, 1, 1).data[3] !== 0) {
        this.setState({
          hoveredHouse: target.id,
        });
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  mouseClick = ev => {
    this.setState({
      visibleCircle: ev.target.id,
    });
  };

  render() {
    const {
      homepage,
      houses,
      houses_additional,
      changeRedirected,
    } = this.props;
    const {
      height,
      width,
      hoveredHouse,
      showOnlyAvailable,
      showHouses,
    } = this.state;

    const widthPr = width / this.basicWidth;
    const heightPr = height / this.basicHeight;
    return (
      <div
        className="container-fluid d-none d-md-block"
        style={{
          position: 'relative',
        }}
      >
        <span className="d-none">{this.props.dateString}</span>
        <div
          id="map-container"
          style={{
            display: 'inline-block',
            position: 'relative',
          }}
        >
          <a
            className="anchor-link text-uppercase"
            onClick={() => {
              this.setState(prevState => ({
                showOnlyAvailable: !prevState.showOnlyAvailable,
              }));
            }}
          >
            <span className={showOnlyAvailable ? 'dot-full' : 'dot'} />
            Pokaż dostępne
          </a>
          <a className="anchor-link-2 text-uppercase" href="#houses-table">
            <span className="dot" />
            Przejdź do listy lokali
          </a>
          <Image
            fluid
            src={`${backendAddress}${homepage.singleton.housesBackground.path}`}
            id="main-background"
          />
          {houses.collection.map(house => {
            const top = house.posY * heightPr;
            const left = house.posX * widthPr;
            const statusClass =
              house.status === 'sprzedany'
                ? 'house-sold'
                : house.status === 'wolny'
                ? 'house-free'
                : 'house-reserved';

            return (
              <React.Fragment key={house._id}>
                <Image
                  crossOrigin="anonymous"
                  id={`${house._id}`}
                  className={classNames('js-house-images house', statusClass, {
                    hover:
                      hoveredHouse === house._id ||
                      (showOnlyAvailable && house.status === 'wolny'),
                  })}
                  src={`${backendAddress}${house.image.path}`}
                  fluid
                  style={{
                    position: 'absolute',
                    top: `${top}px`,
                    left: `${left}px`,
                    visibility: showHouses ? 'visible' : 'hidden',
                    cursor: 'pointer',
                  }}
                  onMouseMove={ev => {
                    ev.preventDefault();
                    ev.persist();
                    this.checkTransparency(ev);
                  }}
                  onTouchStart={ev => {
                    ev.preventDefault();
                    ev.persist();
                    this.checkTransparency(ev);
                  }}
                  onClick={ev => {
                    ev.persist();
                    changeRedirected(house._id);
                    const el = document.getElementById(`house-${house._id}`);
                    $('html,body').animate(
                      {
                        scrollTop: el.offsetTop - $('.footer-top').height() - 8,
                      },
                      1000
                    );
                  }}
                  onTouchEnd={() => {
                    this.setState({
                      hoveredHouse: '',
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      hoveredHouse: '',
                    });
                  }}
                />
                {house._id === hoveredHouse && (
                  <span
                    style={{
                      top: `${top - 100}px`,
                      left: `${left + 100}px`,
                      cursor: 'pointer',
                    }}
                    className={classNames('house-circle', statusClass)}
                    onClick={ev => {
                      changeRedirected(house._id);
                      const el = document.getElementById(`house-${house._id}`);
                      $('html,body').animate(
                        {
                          scrollTop:
                            el.offsetTop - $('.footer-top').height() - 8,
                        },
                        1000
                      );
                    }}
                  >
                    <span>
                      <span className="text-uppercase font-weight-bold">
                        {house.title.replace('L', 'lokal ')}
                      </span>
                      <span className="mt-2">
                        pow. lokalu {house.size} m<sup>2</sup>
                      </span>
                      <span>
                        pow. działki {house.areaSize} m<sup>2</sup>
                      </span>
                      <span>
                        pow. ogrodu {house.gardenSize} m<sup>2</sup>
                      </span>
                      <span>
                        {' '}
                        {house.status === 'wolny' && (
                          <NumberFormat
                            prefix="cena "
                            suffix=" zł"
                            value={house.price}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                          />
                        )}
                      </span>
                      <span className="mt-2 text-uppercase font-weight-bold">
                        {house.status}
                      </span>
                    </span>
                  </span>
                )}
              </React.Fragment>
            );
          })}
          <a
            className="text-uppercase anchor-link-3"
            href={`${backendAddress}/${houses_additional.singleton.pzt}`}
            target="_blank"
          >
            <Image
              src={icon}
              fluid
              style={{
                height: '20px',
              }}
            />
            <span className="ml-2">pobierz PZT inwestycji</span>
          </a>
          <a
            className="text-uppercase anchor-link-4"
            href={`${backendAddress}/${houses_additional.singleton.locals}`}
            target="_blank"
          >
            <Image
              src={icon}
              fluid
              style={{
                height: '20px',
              }}
            />
            <span className="ml-2">pobierz standard wykończenia</span>
          </a>
        </div>
        <div id="houses-table" />
      </div>
    );
  }
}

function createCanvas(_this) {
  var e = document.createElement('canvas');
  e.setAttribute('width', _this.width);
  e.setAttribute('height', _this.height);
  e.setAttribute('id', _this.id + '-canvas');
  e.setAttribute('style', 'display:none;');
  document.body.appendChild(e);
  var cx = e.getContext('2d');
  cx.drawImage(_this, 0, 0, _this.width, _this.height);
  return e;
}

export default HousesMap;
