import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'react-bootstrap/Image';

import Layout from '../components/layout';
import HousesTable from '../components/housesTable';
import HousesMap from '../components/housesMap';
import '../styles/main.scss';
import lokalImg from '../images/icons/lokal.png';
import zegarImg from '../images/icons/zegar.png';
import portfelImg from '../images/icons/portfel.png';
import powLoImg from '../images/icons/pow_lo.png';
import powDzImg from '../images/icons/pow_dz.png';
import typImg from '../images/icons/types.png';

const backendAddress = process.env.GATSBY_COCKPIT_URL;

class HousesPage extends React.Component {
  state = {
    redirectedTo: null,
  };

  changeRedirected = to => {
    this.setState({ redirectedTo: to });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query housesQuery {
            homepage: cockpit {
              singleton(name: "homepage")
            }
            houses: cockpit {
              collection(name: "houses")
            }
            houses_additional: cockpit {
              singleton(name: "houses_additional")
            }
          }
        `}
        render={data => (
          <Layout>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <div className="row">
                <div className="col-md-4 offset-md-2 offset-0 col-12">
                  <div className="mt-5 d-flex">
                    <img src={lokalImg} className="img-fluid icon-sm" />
                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <p className="m-0">
                        <h4 className="m-0">
                          <b>26 lokali</b>
                        </h4>
                        4 i 5 pokoi z garażem i miejscem postojowym
                        <br />
                        lub dwoma miejscami postojowymi
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 offset-0 offset-md-1 col-12">
                  <div className="mt-5 d-flex">
                    <img src={powLoImg} className="img-fluid icon-sm" />
                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <p className="m-0">
                        <h4 className="m-0">
                          <b>
                            105 - 160 m<sup>2</sup>
                          </b>
                        </h4>
                        to powierzchnie lokali
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 offset-md-2 offset-0 col-12">
                  <div className="mt-5 d-flex">
                    <img src={typImg} className="img-fluid icon-sm" />
                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <p className="m-0">
                        <h4 className="m-0">
                          <b>4 typy budynków</b>
                        </h4>
                        każdy klient znajdzie układ odpowiedni dla swoich
                        potrzeb
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 offset-md-1 offset-0 col-12">
                  <div className="mt-5 d-flex">
                    <img src={powDzImg} className="img-fluid icon-sm" />
                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <p className="m-0">
                        <h4 className="m-0">
                          <b>
                            170 - 500 m<sup>2</sup>
                          </b>
                        </h4>
                        to powierzchnia działki przypadająca
                        <br />
                        na lokal mieszkalny
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 offset-md-2 col-12 offset-0">
                  <div className="mt-5 d-flex">
                    <img src={zegarImg} className="img-fluid icon-sm" />
                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <p className="m-0">
                        <h4 className="m-0">
                          <b>16 minut</b>
                        </h4>
                        dokładnie tyle dzieli Zatokę Pucką od wrocławskiego
                        Rynku
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 offset-md-1 offset-0 col-12">
                  <div className="mt-5 d-flex">
                    <img src={portfelImg} className="img-fluid icon-xs" />
                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <p className="m-0">
                        <h4 className="m-0">
                          <b>638.000 zł</b>
                        </h4>
                        to cena jaką zapłacisz za nowy lokal
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <img src={domy_up} className="img-fluid houses-img" />
              </div> */}
            </div>
            <div className="container-fluid d-block d-md-none">
              <Image
                fluid
                src={`${backendAddress}${
                  data.homepage.singleton.housesBackground.path
                }`}
                style={{ opacity: '0.5' }}
              />
              <p className="text-justify justify-text text-center">
                * Aby zobaczyć interaktywny podgląd osiedla otwórz stronę w
                urządzeniu wspierającym wyższą rozdzielczość.
              </p>
            </div>
            <HousesMap
              homepage={data.homepage}
              houses={data.houses}
              houses_additional={data.houses_additional}
              changeRedirected={this.changeRedirected}
            />
            <HousesTable
              houses={data.houses.collection}
              changeRedirected={this.changeRedirected}
              houses_additional={data.houses_additional}
              redirectedTo={this.state.redirectedTo}
            />
          </Layout>
        )}
      />
    );
  }
}

export default HousesPage;
