import React from 'react';
import classNames from 'classnames';
import Image from 'react-bootstrap/Image';
import NumberFormat from 'react-number-format';
import '../styles/houses-table.scss';
import icon from '../images/arrow_down.png';
import VisibilitySensor from 'react-visibility-sensor';

const backendAddress = process.env.GATSBY_COCKPIT_URL;

class HousesTable extends React.Component {
  onChange = (ev, id) => {
    if (this.props.redirectedTo === id && !ev) {
      this.props.changeRedirected(null);
    }
  };
  render() {
    const { houses, redirectedTo, houses_additional } = this.props;
    const housesSorted = houses.sort((x, y) => {
      var x1 = x.title.replace(/\D+/g, '');
      var y1 = y.title.replace(/\D+/g, '');
      if (parseInt(x1) > parseInt(y1)) {
        return 1;
      }
      return -1;
    });

    return (
      <>
        <div
          className="container mt-5"
          style={{
            marginBottom: ' 50px',
          }}
          onScroll={this.handleScroll}
        >
          <h2 className="head-underlined">Jakimi lokalami dysponujemy ?</h2>
          <p className="mt-3 text-justify">
            Dostępne są <b>cztery modele lokali</b>, różniące się ilością pokoi,
            powierzchnią, pomieszczeniami dodatkowymi oraz garażami. W
            zależności od potrzeb, istnieje możliwość swobodnej aranżacji
            wnętrz, czy też zmiany ich funkcjonalności.
          </p>
          <p className="mt-3 text-justify">
            <b>
              Najmniejszy model o powierzchni 106,92 m<sup>2</sup>
            </b>{' '}
            posiada atrakcyjną częścią dzienną o powierzchni ponad 44 m
            <sup>2</sup>, obie łazienki wyposażone w okna, pralnię usytuowaną na
            piętrze oraz ponad 17 metrową sypialnię z garderobą. <br />
            <br />
            <b>
              Większy model o powierzchni 108,06 m<sup>2</sup>
            </b>{' '}
            posiada podobną funkcjonalność, a dodatkowo ma niewielką spiżarnię.
            <br />
            <br />
            <b>
              Model o powierzchni 118,04 m<sup>2</sup> oraz 118,82 m<sup>2</sup>
            </b>{' '}
            występuje w dwóch wariantach. Pierwszy z nich ma garaż na jedno
            auto, niemal 19 metrową sypialnię z garderobą, pralnię i dużą 7
            metrową łazienkę z oknem. Drugi wariant nie posiada garażu, ale ma
            większą część dzienną i dodatkowy pokój usytuowany na parterze,
            który może pełnić funkcję sypialni, biura lub gabinetu.
            <br />
            <br />
            <b>
              Największy model o powierzchni 161,17 m<sup>2</sup>
            </b>{' '}
            posiada część dzienną o powierzchni ponad 45 m<sup>2</sup>, garaż na
            jedno auto, dwie łazienki na piętrze i cztery sypialnie.
          </p>
          <p className="d-block d-md-none">
            <a
              className="text-uppercase anchor-link-3"
              href={`${backendAddress}/${houses_additional.singleton.pzt}`}
              target="_blank"
            >
              <Image
                src={icon}
                fluid
                style={{
                  height: '20px',
                }}
              />
              <span className="ml-2">pobierz PZT inwestycji</span>
            </a>
          </p>
          <p className="d-block d-md-none">
            <a
              className="text-uppercase anchor-link-4"
              href={`${backendAddress}/${houses_additional.singleton.locals}`}
              target="_blank"
            >
              <Image
                src={icon}
                fluid
                style={{
                  height: '20px',
                }}
              />
              <span className="ml-2">pobierz standard wykończenia</span>
            </a>
          </p>
        </div>
        <div
          className="container mt-5"
          style={{
            marginBottom: ' 50px',
          }}
          id="table-of-houses"
        >
          <h2 className="head-underlined">Lista lokali</h2>
          <div className="houses-table">
            {Array.from(housesSorted).map(house => (
              <VisibilitySensor
                onChange={ev => {
                  this.onChange(ev, house._id);
                }}
              >
                <div
                  key={house._id}
                  className={classNames('houses-row', {
                    'low-opacity': redirectedTo && redirectedTo !== house._id,
                  })}
                  name={`house-${house._id}`}
                  id={`house-${house._id}`}
                >
                  <div className="column-one">
                    <div
                      className={`${house.status}-color`}
                      style={{ width: '5%', height: '100%' }}
                    />
                    <span className="ml-5">
                      <p className="text-uppercase">
                        {house.title.replace('L', 'lokal ')}
                      </p>
                      {house.status === 'wolny' ? (
                        <NumberFormat
                          prefix="cena "
                          suffix=" ZŁ"
                          value={house.price}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          className={`${house.status}-font big-price`}
                        />
                      ) : (
                        <span
                          className={`${
                            house.status
                          }-font big-price text-uppercase`}
                        >
                          {house.status}
                        </span>
                      )}
                    </span>
                    {/* <div className="top-part">
                    <span className="font-weight-bold">{house.type}</span>
                    <span>{house.address}</span>
                  </div>
                  <div className="bottom-part">
                    <span className="font-weight-bold">{house.status}</span>
                  </div>
                  <div /> */}
                  </div>

                  <div className="column-two">
                    <div className="left-side">
                      <span className="font-weight-bold">
                        powierzchnia lokalu
                      </span>
                      <span>powierzchnia działki</span>
                      {house.gardenSize && <span>powierzchnia ogrodu</span>}
                      {house.terraceSize && <span>powierzchnia tarasu</span>}
                      <span>{house.parking}</span>
                      <span>kolor elewacji</span>
                    </div>
                    <div className="right-side">
                      <span className="font-weight-bold">
                        {house.size.replace(/[,.]/g, ',')}{' '}
                        <span className="text-lowercase">
                          m<sup>2</sup>
                        </span>
                      </span>
                      <span>
                        {house.areaSize.replace(/[,.]/g, ',')}{' '}
                        <span className="text-lowercase">
                          m<sup>2</sup>
                        </span>
                      </span>
                      {house.gardenSize && (
                        <span>
                          {house.gardenSize.replace(/[,.]/g, ',')}{' '}
                          <span className="text-lowercase">
                            m<sup>2</sup>
                          </span>
                        </span>
                      )}
                      {house.terraceSize && (
                        <span>
                          {house.terraceSize.replace(/[,.]/g, ',')}{' '}
                          <span className="text-lowercase">
                            m<sup>2</sup>
                          </span>
                        </span>
                      )}
                      <span>TAK</span>
                      <span>{house.color}</span>
                    </div>
                  </div>
                  <div className="column-four">
                    <a
                      href={`${backendAddress}/pucka/storage/uploads${
                        house.card.path
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        pobierz kartę <br />
                        informacyjną
                      </span>
                      <Image
                        className="mt-3"
                        src={icon}
                        fluid
                        style={{
                          height: '25px',
                        }}
                      />
                    </a>
                  </div>
                </div>
              </VisibilitySensor>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default HousesTable;
